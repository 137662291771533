<template>
  <div>
    <div class="mb-5 prk-shadow br-15">
      <div v-show="filterSpinner" class="text-center text-danger py-3 pcg-white-background-color">
        <pcg-spinner variant="small"/>
      </div>
      <div v-show="!filterSpinner">
        <syllabuses-list-filters @update:filters="refreshFilters" :syllabusFilters="filters"/>
        <syllabuses-table :filters="filters"
                          @selected="selectedSyllabuses = $event"
                          @loadingSyllabuses="loadingSyllabuses"
                          :addedCoordinators="addedCoordinators"
                          :editedSyllabusIds="editedSyllabusIds"
                          @update:current-page="refreshPage($event)"
                          @stopLoadingSyllabuses="stopLoadingSyllabuses"/>
        <prk-coordinators-modal
            v-if="showCoordinatorsModal"
            :key="rerenderCoordinatorsModal"
            @refreshCoordinators="refreshCoordinators"
            :syllabus-ids="syllabusIds"
            modal-id="coordinators-group"
            @hide="rerenderCoordinatorsModal += 1"
        />
        <statuses-modal :syllabuses="selectedSyllabuses"/>
        <send-email-modal :syllabuses="selectedSyllabuses" />
        <course-effects-modal :syllabus-id="syllabusId" />
      </div>
    </div>
    <portal-target class="mb-5" name="syllabus-pagination" />
  </div>
</template>

<script>
import SyllabusesListFilters from '../../components/syllabus/syllabuses_list/SyllabusesListFilters'
import PrkCoordinatorsModal from '../../components/syllabus/modals/PrkCoordinatorsModal'
import SyllabusesTable from './SyllabusesTable'
import StatusesModal from '../../components/syllabus/modals/StatusesModal'
import SendEmailModal from '../../components/syllabus/modals/PrkSendEmailModal'
import CourseEffectsModal from '../../components/matrices/modals/CourseEffectsModal'
import useFilters from '../../composables/useFilters'
import { mapState, mapGetters, mapActions } from 'vuex'
import Vue from 'vue'

export default {
  name: 'SyllabusesList',
  setup (_, { root }) {
    const { filters, updateFilters } = useFilters(root)
    return { filters, updateFilters }
  },
  components: {
    SendEmailModal,
    StatusesModal,
    SyllabusesTable,
    PrkCoordinatorsModal,
    SyllabusesListFilters,
    CourseEffectsModal,
  },
  data () {
    return {
      selectedSyllabuses: [],
      rerenderCoordinatorsModal: 0,
      filterSpinner: false,
      currentPage: 1,
      addedCoordinators: null,
      editedSyllabusIds: null,
    }
  },
  created () {
    this.filterSpinner = true
    const academicYear = this.$route.query.academic_year || this.filters.academic_year
    const query = this.$route.query
    if (Object.prototype.hasOwnProperty.call(query, 'page')) {
      this.currentPage = query.page
    }
    if (Object.prototype.hasOwnProperty.call(query, 'coordinator_id')) {
      this.refreshFilters(query.coordinator_id, 'coordinator_id')
    }
    if (Object.prototype.hasOwnProperty.call(query, 'faculty_id')) {
      this.refreshFilters(query.faculty_id, 'faculty_id')
    }
    if (Object.prototype.hasOwnProperty.call(query, 'course_name_id')) {
      this.refreshFilters(query.course_name_id, 'course_name_id')
    }
    if (Object.prototype.hasOwnProperty.call(query, 'subject_name')) {
      this.refreshFilters(query.subject_name, 'subject_name')
    }
    if (Object.prototype.hasOwnProperty.call(query, 'study_profile_id')) {
      this.refreshFilters(query.study_profile_id, 'study_profile_id')
    }
    if (Object.prototype.hasOwnProperty.call(query, 'speciality_id')) {
      this.refreshFilters(query.speciality_id, 'speciality_id')
    }
    if (Object.prototype.hasOwnProperty.call(query, 'status')) {
      this.refreshFilters(query.status, 'status')
    }
    if (Object.prototype.hasOwnProperty.call(query, 'semester_type')) {
      this.refreshFilters(query.semester_type, 'semester_type')
    }
    if (Object.prototype.hasOwnProperty.call(query, 'training_cycle')) {
      this.refreshFilters(query.training_cycle, 'training_cycle')
    }
    this.$router.push({
      path: this.$route.path,
      query: { academic_year: academicYear, page: this.currentPage, current_role: this.currentRole, ...this.$_.omit(this.filters, 'academic_year') },
    }).catch(() => {})
    setTimeout(() => {
      this.filterSpinner = false
    }, 4000)
  },
  computed: {
    ...mapState('syllabus', ['syllabusId']),
    ...mapGetters('auth', ['currentRole']),
    showCoordinatorsModal () {
      return ['admin', 'employer'].includes(this.currentRole)
    },
    syllabusIds () {
      return this.selectedSyllabuses.map(syllabus => syllabus.id)
    },
  },
  methods: {
    ...mapActions('syllabus', ['setSyllabusId']),
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.syllabus.list'),
      })
    },
    refreshCoordinators (educators, syllabusIds) {
      this.addedCoordinators = educators
      this.editedSyllabusIds = syllabusIds
      this.selectedSyllabuses = []
    },
    loadingSyllabuses () {
      this.filterSpinner = true
    },
    stopLoadingSyllabuses () {
      this.filterSpinner = false
    },
    refreshPage (page) {
      if (this.currentPage !== page) {
        this.currentPage = page
        this.updateFilters(this.currentPage, 'page')
      }
    },
    refreshFilters (value, field) {
      if (field !== 'page') {
        this.currentPage = 1
        if (field === 'faculty_id') {
          Vue.set(this.filters, field, value)
          Vue.set(this.filters, 'course_name_id', null)
          Vue.set(this.filters, 'subject_name', null)
          Vue.set(this.filters, 'speciality_id', null)
        }
        if (field === 'course_name_id') {
          Vue.set(this.filters, field, value)
          Vue.set(this.filters, 'subject_name', null)
          Vue.set(this.filters, 'speciality_id', null)
        }
      }
      this.updateFilters(value, field)
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  destroyed () {
    this.setSyllabusId('')
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .pcg-table-container {
    border-radius: 0 0 15px 15px;

    .pcg-table-content {
      border-radius: 0 0 15px 15px;
    }
  }

  .box {
    border-radius: 15px 15px 0 0 !important;
    padding: 15px 30px 25px 30px !important;
  }
}
</style>
